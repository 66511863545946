import React, { useEffect, useRef, useState } from 'react';
import QRCodeStyling from 'qr-code-styling';
import { DownloadIcon } from '@heroicons/react/solid';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import { camelCase } from 'lodash';
import classNames from 'classnames';

const qrCode = new QRCodeStyling({
  width: 330,
  height: 330,
  backgroundOptions: {
    color: 'transparent',
  },
  margin: 10,
  image: '/vizion_qr.svg',
  dotsOptions: {
    color: '#fd0',
    type: 'dots',
  },
  cornersSquareOptions: {
    type: 'circle',
    color: '#fd0',
  },
  imageOptions: {
    imageSize: 0.5,
    crossOrigin: 'anonymous',
  },
});

export default function App() {
  const [name, setName] = useState();
  const [title, setTitle] = useState();
  const [url, setUrl] = useState('https://www.vizionapi.com');
  const [fileExt, setFileExt] = useState('png');
  const [preview, setPreview] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const previewRef = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = async () => {
    qrCode.download({
      extension: fileExt,
    });
    // let conversionFn = toSvg;
    // switch (fileExt) {
    //   case 'jpeg':
    //     conversionFn = toJpeg;
    //     break;
    //   case 'png':
    //     conversionFn = toPng;
    //     break;
    //   case 'svg':
    //     conversionFn = toSvg;
    //     break;
    //   default:
    //     break;
    // }
    // const dataUrl = await conversionFn(wrapperRef.current, {
    //   // width: 370,
    //   // canvasWidth: 370,
    //   backgroundColor: '#1e1e1e',
    // });
    // const link = document.createElement('a');
    // link.download = `${name}.${fileExt}`;
    // link.href = dataUrl;
    // link.click();
    // previewRef.current.src = dataUrl;
    // setShowPreview(true)
    // const link = document.createElement('a');
    // const fileName = camelCase(name) || `qrcard-${Date.now()}`
    // link.download = `${fileName}.${fileExt}`;
    // link.href = dataUrl;
    // link.click();
  };

  const onNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const onTitleChange = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  return (
    <div className="App container mx-auto flex flex-col items-center p-4">
      <h1 className="md:text-2xl sm:text-xl m-4 font-bold text-slate-800">
        Vizion QR Business Card Generator
      </h1>
      <div className="instructions border sm:rounded text-left mx-auto mb-4">
        <div className="px-8 py-4">
          <h2 className="text-xl my-2 text-center">Instructions</h2>
          <ol className="list-decimal">
            <li>Enter your name and title in the boxes below</li>
            <li>
              Enter a URL you would like people to land on when scanning your
              code
            </li>
            <li>Select a format (optional)</li>
            <li>Download the file</li>
          </ol>
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div>
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            Your Name
          </label>
          <div className="mt-1 mr-2">
            <input
              value={name}
              onChange={onNameChange}
              name="name"
              id="name"
              className="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Tyler Hughes"
            />
          </div>
        </div>
        <div className="flex-grow">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Job Title
          </label>
          <div className="mt-1">
            <input
              value={title}
              onChange={onTitleChange}
              name="title"
              id="title"
              className="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Co-founder & CTO"
            />
          </div>
        </div>
        <div className="col-span-2 mt-2">
          <div>
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              QR Code URL
            </label>
            <div className="mt-1">
              <input
                value={url}
                onChange={onUrlChange}
                name="url"
                id="url"
                className="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="https://linkedin.com/in/tylerlh"
              />
            </div>
          </div>
        </div>
      </div>
      <img
        ref={previewRef}
        height={wrapperRef.current && wrapperRef.current.contentHeight}
        className={classNames({ hidden: !showPreview })}
      />
      <div
        className={classNames(
          'output-container',
          'flex',
          'flex-col',
          'items-center',
          'mt-8',
          { hidden: showPreview }
        )}
      >
        <div style={styles.qrWrapper} ref={wrapperRef}>
          <h1 className="text-4xl text-gray-100 text-center py-4 pt-8 max-w-xs break-words">
            {name || 'Your Name'}
          </h1>
          <h3 className="text-xl text-gray-100 text-center mb-4 max-w-xs break-words">
            {title || 'Your Title'}
          </h3>
          <div className="mx-auto" ref={ref} />
        </div>
      </div>
      <div className="flex mx-auto mt-8">
        <div className="mr-2">
          <label
            htmlFor="fileExt"
            className="block text-sm font-medium text-gray-700"
          >
            Select Format
          </label>
          <select
            name="fileExt"
            onChange={onExtensionChange}
            value={fileExt}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="png">PNG</option>
            <option value="svg">SVG</option>
            <option value="jpeg">JPEG</option>
          </select>
        </div>
        <button
          className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={onDownloadClick}
        >
          <DownloadIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Download
        </button>
      </div>
    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20,
  },
  name: {
    color: '#dedede',
    marginBottom: '.5rem',
    paddingTop: 20,
    paddingBottom: 0,
  },
  title: {
    marginTop: 0,
    color: '#dedede',
    marginBottom: '3rem',
  },
  qrWrapper: {
    // width: 370,
    // height: 600,
    backgroundColor: '#1e1e1e',
    paddingBottom: '4rem',
  },
};
